import { useRoutes, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth'

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import { auth } from 'firebase.js';
import { useEffect } from 'react';
import { useAuth } from 'hooks/AuthContext';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            if (window.location.pathname === '/login') {
                navigate('/', { replace: true });
            }
        }
        if (!currentUser) {
            if (window.location.pathname !== '/login') {
                navigate('/login', { replace: true });
            }
        }
    }, [currentUser, navigate]);

    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
