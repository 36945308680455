import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { isLoaded } from 'react-redux-firebase';
import { useEffect } from 'react';
import { AuthProvider } from 'hooks/AuthContext';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router';
import { auth } from 'firebase.js';
import Loader from 'ui-component/Loader';
import { UserProvider } from 'hooks/UserContext';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <AuthProvider>
                    <NavigationScroll>
                        <UserProvider>
                            <Routes />
                        </UserProvider>
                    </NavigationScroll>
                </AuthProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
