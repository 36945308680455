import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { auth } from 'firebase.js';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const logInWithEmailAndPassword = async (email, password) => {
        try {
            await signInWithEmailAndPassword(auth, email, password).then((user) => {
                console.log('logged in');
                setLoading(false);
                navigate('/', { replace: true });
            });
        } catch (error) {
            setError(error);
            console.log(error);
        }
    };

    const logout = () => {
        signOut(auth);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        logInWithEmailAndPassword,
        logout
    };

    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}


const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password).then((user) => {
            console.log('logged in');
        });
    } catch (error) {
        setError(error);
        console.log(error);
    }
};

const logout = () => {
    signOut(auth);
};

export {
    logInWithEmailAndPassword,
    logout
}
