import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { auth, db } from 'firebase.js';
import { useAuth } from './AuthContext';
import { collection, doc, getDoc, query } from 'firebase/firestore';

const UserContext = React.createContext();

export function useUser() {
    return useContext(UserContext);
}

export function UserProvider({ children }) {
    const [poolPartner, setPoolPartner] = useState(null);
    const [loading, setLoading] = useState(true);


    const { currentUser } = useAuth();
    useEffect(() => {
        if (currentUser) {
            getUserInfo();
        } else {
            setLoading(false);
        }
    }, [currentUser]);

    const getUserInfo = () => {
        try {
            setLoading(true);
            const user = currentUser.uid;
            const poolPartnerRef = doc(db, 'poolPartner', user);
            getDoc(poolPartnerRef).then(response => {
                setPoolPartner(response.data());
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.log(err);
            });
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };



    const value = {
        poolPartner,
    };

    return <UserContext.Provider value={value}>{!loading && children}</UserContext.Provider>;
}