// assets
import { IconCalendar, IconCalendarEvent } from '@tabler/icons';

// constant
const icons = {
    IconCalendar,
    IconCalendarEvent
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'schedule',
    title: 'Schedule',
    type: 'group',
    children: [
        {
            id: 'todaysLessons',
            title: 'Today\'s Lessons',
            type: 'item',
            url: '/',
            icon: icons.IconCalendar,
            breadcrumbs: false
        },
        {
            id: 'fullSchedule',
            title: 'Full Schedule',
            type: 'item',
            url: '/full-schedule',
            icon: icons.IconCalendarEvent,
            breadcrumbs: false
        },
    ]
};

export default dashboard;
